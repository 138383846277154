<template>
	<div>
		<b-row>
			<b-col lg="8" cols="12" md="8">
				<h4 class="card-title">Loan Applications Status</h4>
				<h5 class="card-subtitle mb-0 font-weight-normal text-muted">
					Total applications by state
				</h5>

				<b-row class="my-4">
					<b-col lg="3" cols="3" md="3" class="border-right mb-2 card-btn" @click="goToLoans('fill-additional-details')">
						<i class="mdi mdi-checkbox-blank-circle text-primary"></i>
						<h3 class="mb-0">{{ status.fillDetails }}</h3>
						<span>Fill Additional Details</span>
					</b-col>
					<b-col lg="3" cols="3" md="3" class="border-right mb-2 card-btn" @click="goToLoans('membercheck')">
						<i class="mdi mdi-checkbox-blank-circle text-purple"></i>
						<h3 class="mb-0">{{ status.membercheck }}</h3>
						<span>Membercheck</span>
					</b-col>
					<b-col lg="3" cols="3" md="3" class="border-right mb-2 card-btn" @click="goToLoans('screening')">
						<i class="mdi mdi-checkbox-blank-circle text-success"></i>
						<h3 class="mb-0">{{ status.screening }}</h3>
						<span>Screening</span>
					</b-col>
					<b-col lg="3" cols="3" md="3" class="border-right mb-2 card-btn" @click="goToLoans('job-verification')">
						<i class="mdi mdi-checkbox-blank-circle text-secondary"></i>
						<h3 class="mb-0">{{ status.jobVerification }}</h3>
						<span>JobVerification</span>
					</b-col>
					<b-col lg="3" cols="3" md="3" class="border-right mb-2 card-btn" @click="goToLoans('verify-all-information')">
						<i class="mdi mdi-checkbox-blank-circle" style="color: #0984e3;"></i>
						<h3 class="mb-0">{{ status.verifyAllInformation }}</h3>
						<span>Verify All Information</span>
					</b-col>
					<b-col lg="3" cols="3" md="3" class="border-right mb-2 card-btn" @click="goToLoans('approval-lvl-1.2')">
						<i class="mdi mdi-checkbox-blank-circle text-warning"></i>
						<h3 class="mb-0">{{ status.approved }}</h3>
						<span>Approval</span>
					</b-col>
					<b-col lg="3" cols="3" md="3" class="border-right mb-2 card-btn" @click="goToLoans('signature')">
						<i class="mdi mdi-checkbox-blank-circle" style="color: #6c5ce7;"></i>
						<h3 class="mb-0">{{ status.signed }}</h3>
						<span>Signature</span>
					</b-col>
					<b-col lg="3" cols="3" md="3" class="border-right mb-2 card-btn" @click="goToLoans('transfer-of-funds')">
						<i class="mdi mdi-checkbox-blank-circle " style="color: #d63031!important;"></i>
						<h3 class="mb-0">{{ status.transferredFunds }}</h3>
						<span>Transfer of funds</span>
					</b-col>
				</b-row>
			</b-col>
			<b-col lg="4" cols="12" md="4" class="d-flex align-items-center">
				<div class="mt-4 text-center">
					<vue-apex-charts type="donut" height="150" :options="chartOptions" :series="series"></vue-apex-charts>
				</div>
			</b-col>
		</b-row>
	</div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";

export default {
	name: "TotalEarnings",
	props: {
		status: Object,
	},
	data: () => ({
		title: "TotalEarnings",
		chartOptions: {
			chart: {
				fontFamily: '"Nunito Sans",sans-serif',
				foreColor: "#a1aab2",
			},
			labels: ["Fill details", "Membercheck", "Screening", "Job Verification", "verify All Information", "Approved", "Signed", "Transferred Funds"],
			dataLabels: {
				enabled: false,
			},
			grid: {
				padding: {
					left: 0,
					right: 0,
				},
				borderColor: "rgba(0,0,0,0.1)",
			},
			plotOptions: {
				pie: {
					donut: {
						size: "75px",
						labels: {
							show: true,
							name: {
								show: true,
								offsetY: 10,
							},
							value: {
								show: false,
							},
							total: {
								show: true,
								fontSize: "13px",
								color: "#a1aab2",
								label: "Status",
							},
						},
					},
				},
			},
			stroke: {
				width: 0,
			},
			legend: {
				show: false,
			},
			colors: ["#0A505D", "#C8BBFF", "#6BD6D3", "#898989", "#0984e3", "#8E44AD", "#6c5ce7", "#d63031"],
			tooltip: {
				fillSeriesColor: false,
			},
		},
		series: [],
	}),
	components: {
		VueApexCharts,
	},
	methods: {
		goToLoans(status) {
			this.$router.push("/loans/applications?slug=" + status);
		},
	},
	created() {
		this.series = [
			this.status.fillDetails,
			this.status.membercheck,
			this.status.screening,
			this.status.jobVerification,
			this.status.verifyAllInformation,
			this.status.approved,
			this.status.signed,
			this.status.transferredFunds,
		];
	},
};
</script>

<style>
.card-btn {
	cursor: pointer;
}
.card-btn:hover {
	background: #f3f3f3;
}
</style>
